import { useStorage } from '@vueuse/core';

const maxItems = 8;
const currentItem = ref<string | null>(null);

export const useLastSeen = (type: 'product' | 'category') => {
    const _lastSeen = useStorage<string[]>(`lastSeen-${type}`, []);

    const filteredLastSeen = computed(() => {
        const lastSeenClone = [..._lastSeen.value];

        if (!currentItem.value) {
            return lastSeenClone.splice(0, maxItems);
        }

        return lastSeenClone.filter((item) => item !== currentItem.value).splice(0, maxItems);
    });

    const addLastSeen = (item: string): void => {
        if (!item) return;

        currentItem.value = item;

        // needs to be process.client for vitest
        // eslint-disable-next-line
        if (!process.client) return;

        if (_lastSeen.value.includes(item)) {
            // move item to first position
            _lastSeen.value.sort((a, b) => (a === item ? -1 : b === item ? 1 : 0));
        } else {
            // add item to first position
            _lastSeen.value.unshift(item);
        }

        // cap last seen at limit + 1 to always get a maximum of maxItems in frontend (because currentItem is in list and not showed)
        _lastSeen.value = _lastSeen.value.splice(0, maxItems + 1);
    };

    const resetCurrentItem = () => (currentItem.value = null);

    return {
        lastSeen: filteredLastSeen,
        addLastSeen,
        resetCurrentItem,
    };
};
